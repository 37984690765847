<template>
  <section>
    <v-main>
    <router-view />
    </v-main>
  </section>
</template>
<script>
export default {
  name: "BlankLayout",
};
</script>